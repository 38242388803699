<template>
  <b-card
    :title="!isViewPage ? 'Update FAQ' : 'View FAQ'"
    class="admin-faq-manage"
  >
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <label class="form-extra-label">{{ $t('Category') }}</label>
          <b-form-select
            v-model="faqDetail.category_id"
            :options="faqCategoryOptions"
            :disabled="isViewPage"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <label class="form-extra-label">{{ $t('Title') }}</label>
          <b-form-input
            v-model="faqDetail.title"
            type="text"
            :disabled="isViewPage"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-form-group :label="$t('Status')">
            <b-form-select
              v-model="faqDetail.status"
              :disabled="isViewPage"
              :options="[
                {
                  text: 'Active',
                  value: 1,
                },
                {
                  text: 'Inactive',
                  value: 0,
                },
              ]"
            />
          </b-form-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label class="form-extra-label">{{ $t('Sub Title') }}</label>
          <b-form-input
            v-model="faqDetail.subtitle"
            type="text"
            :disabled="isViewPage"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label class="form-extra-label">{{ $t('Description') }}</label>
          <b-form-input
            v-model="faqDetail.description"
            type="text"
            :disabled="isViewPage"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="showQuestionaries"
        cols="12"
        md="12"
        class="text-center"
      >
        <h3>{{ $t('Questionaries') }}</h3>
      </b-col>
      <b-col
        v-for="(item, index) of questions"
        :key="index"
        cols="12"
        md="12"
      >
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group>
              <label class="form-extra-label">{{ $t('Question') }}</label>
              <b-form-input
                v-model="item.question"
                type="text"
                :disabled="isViewPage"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group>
              <label class="form-extra-label">{{ $t('Answer') }}</label>
              <b-form-input
                v-model="item.ans"
                type="text"
                :disabled="isViewPage"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="flex-position-faq"
          >
            <b-button
              v-if="!isViewPage"
              :disabled="isViewPage"
              variant="primary"
              @click="addQuestion"
            >
              {{ $t('Add') }}
            </b-button>
            <b-button
              v-if="index !== 0 && !isViewPage"
              :disabled="isViewPage"
              variant="danger"
              class="ml-2"
              @click="removeQuestion(item.id)"
            >
              {{ $t('Remove') }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-if="!isViewPage"
        cols="12"
        md="12"
        class="text-center"
      >
        <b-button
          variant="primary"
          @click="createFAQ"
        >
          {{ globalFAQId ? 'Update FAQ' : 'Create FAQ' }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormGroup, BFormSelect, BFormInput, BButton } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useAdminUi } from '../useAdmin'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
  },
  data() {
    return {
      faqDataDetail: {},
      faqDetail: {},
      questions: [],
      faqCategories: [],
      isViewPage: false,
      showQuestionaries: true,
      globalFAQId: null,
    }
  },
  computed: {
    faqCategoryOptions() {
      return [
        ...[
          {
            text: 'Select Category',
            value: '',
          },
        ],
        ...this.faqCategories.map(x => ({
          text: x.name,
          value: x.id,
        })),
      ]
    },
  },
  mounted() {
    this.globalFAQId = this.$route.params.faqIdEdit || this.$route.params.faqIdView || null
    if (this.showQuestionaries) {
      this.addQuestion()
    }
    if (this.globalFAQId) {
      this.getFAQById()
    }
    this.isViewPage = this.$route.params.faqIdView != null
    this.getFAQCategoryList()
  },
  methods: {
    addQuestion() {
      this.questions.push({
        question: '',
        ans: '',
      })
    },
    getFAQById() {
      const { getFAQById } = useAdminUi()
      showLoader()
      getFAQById(this.globalFAQId)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.faqDataDetail = data.responseData
            this.faqDetail.id = this.faqDataDetail.id
            this.faqDetail.category_id = this.faqDataDetail.category_id
            this.faqDetail.title = this.faqDataDetail.title
            this.faqDetail.status = this.faqDataDetail.status
            this.faqDetail.subtitle = this.faqDataDetail.subtitle
            this.faqDetail.description = this.faqDataDetail.description
            this.questions = []
            if (this.faqDataDetail.questions && this.faqDataDetail.questions.length > 0) {
              this.questions = this.faqDataDetail.questions.map(x => ({
                question: x.question,
                ans: x.answer,
                id: x.id,
              }))
            }
            if (this.questions.length === 0 && this.$route.params.faqIdEdit) {
              this.addQuestion()
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getFAQCategoryList() {
      const { getCategories } = useAdminUi()
      getCategories()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.faqCategories = data.responseData
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    removeQuestion(id) {
      this.questions = this.questions.filter(x => x.id !== id)
    },
    createFAQ() {
      const formData = new FormData()
      formData.append('title', this.faqDetail.title)
      formData.append('subtitle', this.faqDetail.subtitle)
      formData.append('category_id', this.faqDetail.category_id)
      formData.append('description', this.faqDetail.description)
      formData.append('status', this.faqDetail.status)
      this.questions.filter(x => x.question && x.ans).forEach((element, index) => {
        formData.append(`questionaries[${index}][question]`, element.question)
        formData.append(`questionaries[${index}][answer]`, element.ans)
        if (this.globalFAQId && element.id) {
           formData.append(`questionaries[${index}][id]`, element.id)
        }
      })
      if (this.globalFAQId) {
        formData.append('id', this.globalFAQId)
        formData.append('_method', 'PATCH')
      }
      showLoader()
      const { addFAQ, updateFAQ } = useAdminUi()
      const payloadCaller = !this.globalFAQId ? addFAQ : updateFAQ
      payloadCaller({
        data: formData,
        query: this.globalFAQId ? `${this.globalFAQId}` : '',
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.$router.push({
              path: '/admin/cmspages/faq',
            })
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.flex-position-faq {
  align-items: center;
  justify-content: flex-start;
  display: flex;
}
</style>
